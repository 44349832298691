module.exports={
  "name": "@uppy/thumbnail-generator",
  "description": "Uppy plugin that generates small previews of images to show on your upload UI.",
  "version": "1.2.0",
  "license": "MIT",
  "main": "lib/index.js",
  "types": "types/index.d.ts",
  "keywords": [
    "file uploader",
    "uppy",
    "uppy-plugin",
    "thumbnail",
    "preview",
    "resize"
  ],
  "homepage": "https://uppy.io",
  "bugs": {
    "url": "https://github.com/transloadit/uppy/issues"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/transloadit/uppy.git"
  },
  "dependencies": {
    "@uppy/utils": "1.2.0",
    "exif-js": "2.3.0"
  },
  "devDependencies": {
    "@uppy/core": "1.2.0",
    "namespace-emitter": "^2.0.1"
  },
  "peerDependencies": {
    "@uppy/core": "^1.0.0"
  }
}
